
import React,{ useEffect, useState }  from 'react';
import logo from '../../Assets/images/logo.png'
import {Accordion} from 'react-bootstrap'
import { NavLink, useHistory } from 'react-router-dom';
import smalllogo from '../../Assets/images/favicon.png'
import dash from '../../Assets/images/side/dash.svg'
import mngadmin from '../../Assets/images/side/mngadmin.svg'
import mnguser from '../../Assets/images/side/mnguser.svg'
// import mnghost from '../../Assets/images/side/mnghost.svg'
import hamburgur from '../../Assets/images/Admin/hamburgur.svg';
import Api from '../../Assets/images/API.svg';
import Emailsetting from '../../Assets/images/Emailsettings.svg';
import Dashboard from '../../Assets/images/dashboard.svg';
import Joinus from '../../Assets/images/joinus.svg';
import Manageadmin from '../../Assets/images/manageadmin.svg';
import Manageamenity from '../../Assets/images/ManageAmmenties.svg';
import Managebooking from '../../Assets/images/manageBooking.svg';
import Managecancellation from '../../Assets/images/ManageCancellationploicy.svg';
import Managecoupon from '../../Assets/images/ManageCoupon.svg';
import Manageexperience from '../../Assets/images/Manageexperiencecategory.svg';
import Managefee from '../../Assets/images/Managefee.svg';
import Managefinancereport from '../../Assets/images/Managefinancereport.svg';
import Managefloorplan from '../../Assets/images/managefloorplan.svg';
import Manageguestuser from '../../Assets/images/manageguestuser.svg';
import Managehostuser from '../../Assets/images/ManageHostuser.svg';
import Managehostingexperience from '../../Assets/images/Managehostingexperience.svg';
import Managelegal from '../../Assets/images/ManageLegal.svg';
import Managedescription from '../../Assets/images/Managhedescription.svg';
import Paymentgateway from '../../Assets/images/Paymentgateway.svg';
import Manageprivacytype from '../../Assets/images/Manageprivacytype.svg';
import Manageproperty from '../../Assets/images/Manageproperty.svg';
import Managepropertytypegroup from '../../Assets/images/Managepropertygroup.svg';
import Managereport from '../../Assets/images/Managereport.svg';
import Manageroom from '../../Assets/images/Manageroom.svg';
import Managedispute from '../../Assets/images/Manage-dispute.svg';
import Managewhatwillprovide from '../../Assets/images/managewhatwillyouprovide.svg';
import Sitesettings from '../../Assets/images/Sitesettings.svg';


const Sidebar = () =>{
    const {push} = useHistory();
    const sidetoggle = () =>{
        if(document.getElementById("dashboard").classList.contains("showsidebar")){
            document.getElementById("dashboard").classList.remove("showsidebar")
            document.getElementById("dashboard").classList.add("hidesidebar")
        }
        else if(document.getElementById("dashboard").classList.contains("hidesidebar")){
            document.getElementById("dashboard").classList.remove("hidesidebar")
            document.getElementById("dashboard").classList.add("showsidebar")
        }
    }

    useEffect(()=>
    {
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[])
    return(
        <div className="sidebar">
            <div className='text-center py-4' onClick={()=>{push("/")}}>
                <img src={logo} className="adminlogo"/>
                <img src={smalllogo} className="adminsmalllogo"/>
                </div>
            <button className='togglebtn' onClick={() => sidetoggle()} ><img src={hamburgur} className="ham"/></button>
            <ul className='themescroll' id="scrollbar_sidebar_id">
                <li><NavLink to="/dashboard"  className="nounder"><img src={Dashboard} />
                 <span>Dashboard</span></NavLink>                
                </li>
              <li><NavLink to="/manageadmin" className="nounder" id="manageadmin"><img src={Manageadmin}/> <span>Manage admin</span></NavLink></li>
                <li><NavLink to="/manageuser" className="nounder" id="manageguestuser"><img src={Manageguestuser}/> <span>Manage Guest Users</span></NavLink></li>
                <li><NavLink to="/managehost" className="nounder" id="managehostuser"><img src={Managehostuser}/> <span>Manage Host Users</span></NavLink></li>
                <li><NavLink to="/manageroom" className="nounder" id="manageroom"><img src={Manageroom}/> <span>Manage Rooms</span></NavLink></li>
                {/* <li> <span>Manage Bookings</span></li> */}
                <li><NavLink to="/managebooking" className="nounder" ><img src={Managebooking}/> <span>Manage Bookings</span></NavLink></li>
                {/* <li><NavLink to="/manage-date" className="nounder" ><img src={Managebooking}/> <span>Manage Stay Date</span></NavLink></li> */}
                <li><NavLink to="/manage-subscription" className="nounder" id="managesubscription"><img src={Managebooking}/> <span>Manage Subscription</span></NavLink></li>
                {/* <li> <span>Manage Hosting Detail</span></li> */}
                <li><NavLink to="/managefloorplan" className="nounder" id="managefloorplan"><img src={Managefloorplan}/> <span>Manage Floor Plan</span></NavLink></li>
                <li><NavLink to="/managepropertytype" className="nounder" id="managepropertytype"><img src={Manageproperty}/> <span>Manage Property Type</span></NavLink></li>
                <li><NavLink to="/managelegal" className="nounder" id="managelegal"><img src={Managelegal}/> <span>Manage Legal</span></NavLink></li>
                <li><NavLink to="/manageprivacytype" className="nounder" id="manageprivacytype"><img src={Manageprivacytype}/> <span>Manage Privacy Type</span></NavLink></li>
                <li><NavLink to="/managepropertytypegroup" className="nounder" id="managepropertytypegroup"><img src={Managepropertytypegroup}/> <span>Manage Property Type Group</span></NavLink></li>
                <li><NavLink to="/managedescription" className="nounder" id="managegdescription"><img src={Managedescription}/> <span>Manage Description</span></NavLink></li>
                <li><NavLink to="/manageamenities" className="nounder" id="manageamenity"><img src={Manageamenity}/> <span>Manage Amenities</span></NavLink></li>
                <li><NavLink to="/manage-category" className="nounder" id="managecategory"><img src={Manageamenity}/> <span>Manage Categories</span></NavLink></li>
                
                <li><NavLink to="/managecancellationpolicy" className="nounder" id="managecancellationpolicy"><img src={Managecancellation}/> <span>Manage Cancellation Policy</span></NavLink></li>
                {/* <li><NavLink to="/managecoupon" className="nounder" ><img src={Managecoupon}/> <span>Manage Coupon</span></NavLink></li> */}
                <li><NavLink to="/managedispute" className="nounder" id="managedispute"><img src={Managedispute}/> <span>Manage Dispute</span></NavLink></li>
                {/* <li><NavLink to="/manage-crm-dispute" className="nounder" ><img src={Managedispute}/> <span>Manage CRM Dispute</span></NavLink></li> */}
                {/* <li> <span>Manage Experience Details</span></li> */}
                {/* <li><NavLink to="/experience-category" className="nounder" id="manageexperiencecategory"><img src={Manageexperience}/> <span>Manage Experience Category</span></NavLink></li> */}
                {/* <li><NavLink to="/what-you-will-provide" className="nounder" id="manageprovide"><img src={Managewhatwillprovide}/> <span>Manage What Will You Provide Your Experience</span></NavLink></li> */}
                {/* <li><NavLink to="/manage-hosting-experience" className="nounder" id="managehostingexperience"><img src={Managehostingexperience}/> <span>Manage Hosting Experience</span></NavLink></li> */}
                <li><NavLink to="/manage-fee" className="nounder" id="managefee"><img src={Managefee}/> <span>Manage Fee</span></NavLink></li>
                <li><NavLink to="/manage-report" className="nounder" ><img src={Managereport}/> <span>Manage Report</span></NavLink></li>
                {/* <li><NavLink to="/manage-emailtemplates" className="nounder" id="manageemailtemplate"><img src={Emailsetting}/> <span>Email Templates</span></NavLink></li> */}
                <li><NavLink to="/manage-finance-report" className="nounder" ><img src={Managefinancereport}/> <span>Manage Finance Report</span></NavLink></li>
                {/* <li><NavLink to="/api-credential" className="nounder" ><img src={Api}/> <span>Api Credential</span></NavLink></li> */}
                {/* <li><NavLink to="/payment-gateway" className="nounder" ><img src={Paymentgateway}/> <span>Payment Gateway</span></NavLink></li> */}
                <li><NavLink to="/send-email" className="nounder" ><img src={Emailsetting}/> <span>Send Email</span></NavLink></li>
                {/* <li><NavLink to="/sitesettings" className="nounder" ><img src={Sitesettings}/> <span>Site Setting</span></NavLink></li> */}
                {/* <li><NavLink to="/emailsettings" className="nounder" ><img src={Emailsetting}/> <span>Email Setting</span></NavLink></li> */}
                <li><NavLink to="/joinus" className="nounder" ><img src={Joinus}/> <span>Join Us</span></NavLink></li>
                <li><NavLink to="/manage-cms" className="nounder" id="managecms"><img src={Joinus}/> <span>Manage CMS</span></NavLink></li>
                <li><NavLink to="/manage-about-cms" className="nounder" id="managecms"><img src={Joinus}/> <span>Manage About CMS</span></NavLink></li>
                <li><NavLink to="/faq" className="nounder" id="managefaq"><img src={Joinus}/> <span>Manage FAQ</span></NavLink></li>
                <li><NavLink to="/manage-landingcms" className="nounder" id="managecms"><img src={Joinus}/> <span>Manage Landing CMS</span></NavLink></li>
                {/* <li><NavLink to="/manage-experience-booking" className="nounder" ><img src={Joinus}/> <span>Manage Experience Booking</span></NavLink></li> */}
                <li><NavLink to="/manage-refund" className="nounder" id="managerefund"><img src={Joinus}/> <span>Manage Refund</span></NavLink></li>
                <li><NavLink to="/manage-withdraw" className="nounder" id="managewithdraw"><img src={Joinus}/> <span>Manage Withdraw</span></NavLink></li>
                <li><NavLink to="/manage-splitday" className="nounder" id="managewithdraw"><img src={Joinus}/> <span>Manage splitdays</span></NavLink></li>
                <li><NavLink to="/manage-contactus" className="nounder" id="managewithdraw"><img src={Joinus}/> <span>Manage Contactus</span></NavLink></li>
            
            </ul>
        </div>
    )
}


export default Sidebar