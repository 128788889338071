import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { getallcrmhook, updatedisputehook } from '../../../Hooks/adminHook';
const FormView = () =>{
    const location = useLocation();
    const {push} = useHistory();
    const [ticketdata , setTicketdata] = useState({});
  const [ticketid , setTicketid] = useState('');
  const [refbookingid , setRefbookingid] = useState('');
  const [raiser , setRaiser] = useState('');
  const [subject , setSubject] = useState('');
  const [description , setDescription] = useState('');
  const [attachment , setAttachment] = useState('');
  const [status , setStatus] = useState('');
  const [statusreason , setStatusreason] = useState('');
  const [assignedto , setAssignedto] = useState('');
  const [crm , setCrm] = useState([]);

  useEffect(()=>{
    document.getElementById("managedispute").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
        },[]);


  useEffect(()=>{
      async function fetchdata(){
        console.log("location data" , location?.state?.state);
        setTicketdata(location?.state?.state)
        setStatus(location?.state?.state?.status);
        setAssignedto(location?.state?.state?.assignedto)
          await getallcrmhook().then((result)=>{
              console.log("get all crm hook result" , result?.data?.data?.record);
              setCrm(result?.data?.data?.record);
          })
      }
      fetchdata();
      
  },[])
  
  const handlesubmit = async()=>{
    console.log("assignedto_assignedto", assignedto)
    if(assignedto?.length>0 ){
        let payload = {
            ticketid : location?.state?.state?.ticketid,
            status : status,
            assignedto : assignedto
        };
        console.log("payload" , payload);
        await updatedisputehook(payload).then((result)=>{
            console.log("update dispute hook result" , result);
            push("/managedispute");
        })
    }
  }

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Update Dispute</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                       
                            <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Ticket Id"
                                 >
                            <Form.Control placeholder='demo' type="text" value={ticketdata?.ticketid} readOnly = {true} />
                            </FloatingLabel>
                            </div>
                            <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Raiser"
                                 >
                                <Form.Control placeholder='demo' type="text" value={ticketdata?.raiser} readOnly ={true} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                        <FloatingLabel controlId="floatingSelect" label="Status *">
                            <Form.Select aria-label="Floating label select example" value = {status} onChange={(e)=>{setStatus(e?.target?.value)}}>
                                <option value = "">Select Status</option>
                   
                                <option >Pending</option>
                                <option >Raised</option>
                     

                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            {/* <div className="col-sm-6 mb-4" >
                            <FloatingLabel controlId="floatingSelect" label="Assign to *">
                            <Form.Select aria-label="Floating label select example" value={assignedto} onChange={(e)=>{setAssignedto(e?.target?.value)}}>
                                <option value = "">Select CRM</option>
                                {crm?.map((data)=><option >{data}</option>)}
                                
                            </Form.Select>
                            </FloatingLabel>
                            </div> */}
                        </div>
                        
                        
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/managedispute")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView